<template>
  <div id="requests">
    <b-alert variant="info" :show="!$store.state.user">
      <b-row>
        <b-col cols="auto" class="my-auto">
          <NetvsIcon icon="info" size="3x" />
        </b-col>
        <b-col class="h-100 my-auto">
          <p class="my-auto">
            {{ $t('views.requests.info') }}
            <router-link to="/login" class="ml-2"><b-button variant="outline-primary">{{ $t('system.login') }}</b-button></router-link>
          </p>
        </b-col>
      </b-row>
    </b-alert>
    <b-row align-v="stretch">
      <b-col v-for="option in visible_options" :key="option.key" xl="6" lg="12">
        <b-card class="mb-3 shadow-sm h-100" no-body
                :header="$t(`views.requests.${option.key}.label`)">
          <b-card-body>
            <p v-html="$t(`views.requests.${option.key}.description`)"></p>
          </b-card-body>
          <b-card-footer>
            <div class="text-alert no-perm text-center mb-2" v-if="option.disabled_text && option.disabled_text()">
              {{ $t(option.disabled_text()) }}
            </div>
            <b-button v-if="option.disabled_text && option.disabled_text()" variant="outline-secondary" block disabled class="cursor-disabled" > {{
              $t(`views.requests.${option.key}.label`)
            }}
            </b-button>
            <b-button v-else variant="primary" block :href="option.address" v-b-modal="option.modal_id">{{
                $t(`views.requests.${option.key}.label`)
              }}
              <netvs-icon v-if="option.address" icon="external_link"/>
              <b-badge v-if="option.badge_content" variant="light" pill>{{ option.badge_content }}</b-badge>
            </b-button>
          </b-card-footer>

          <component :is="option.modal"></component>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PatchRequestForm from '@/components/PatchRequestForm'
import BCDRequestForm from '@/components/BCDRequestForm.vue'
import apiutil from '@/util/apiutil'
import NetvsIcon from '@/icons/NETVSIcon.vue'

export default {
  name: 'RequestOverview',
  components: { NetvsIcon, PatchRequestForm},
  data() {
    const self = this
    return {
      options: [
        {
          modal: PatchRequestForm,
          modal_id: 'patch-request-form',
          key: 'patch_request',
          badge_content: this.$store.getters.patch_request_actions_count,
          visible() {
            return self.$store.state.sysinfo.js_mods.includes('patchrequest') && (apiutil.checkPermission(self.$store.state, 'nd.ro:module') || self.$store.state.is_ou_admin)
          },
          disabled_text() {
            if (!self.$store.state.sysinfo.js_mods.includes('patchrequest')) {
              return null
            }
            if (!apiutil.checkPermission(self.$store.state, 'nd.ro:module') && !self.$store.state.is_ou_admin) {
              return 'views.requests.patch_request.no_permission'
            }
            return null
          }
        },
        {
          modal: BCDRequestForm,
          modal_id: 'bcd-request-form',
          key: 'bcd_request',
          visible() {
            return self.$store.state.sysinfo.js_mods.includes('bcdrequest') && self.$store.state.is_ou_admin
          },
          disabled_text() {
            if (!self.$store.state.sysinfo.js_mods.includes('bcdrequest')) {
              return null
            }
            if (!self.$store.state.is_ou_admin) {
              return 'views.requests.bcd_request.no_permission'
            }
            return null
          }
        },
        {
          address: this.$store.state.sysinfo.netdb_admin_base + '/~netadmin/patch_request/',
          key: 'patch_request_cs',
          visible() {
            return !self.$store.state.sysinfo.js_mods.includes('patchrequest')
          }
        },
        {
          address: 'https://www.scc.kit.edu/ts-patch-request-cn',
          key: 'patch_request_cn',
          visible() {
            return !self.$store.state.sysinfo.js_mods.includes('patchrequest')
          }
        },
        {
          address: 'https://www.scc.kit.edu/ts-iot-request',
          key: 'kit_iot_ticket'
        },
        {
          address: 'https://www.scc.kit.edu/ts-bcd-request',
          key: 'new_bcd_net'
        },
        {
          address: 'https://www.scc.kit.edu/ts-vpn2vlan',
          key: 'vpn2vlan_access'
        },
        {
          address: 'https://www.scc.kit.edu/ts-wifi2vlan',
          key: 'wifi2vlan_access'
        },
        {
          address: 'https://www.scc.kit.edu/ts-dhcp-for-bcd',
          key: 'dhcp_for_bcd'
        },
        {
          address: 'https://www.scc.kit.edu/ts-fw-rule',
          key: 'new_fw_rule'
        },
        {
          address: 'https://www.scc.kit.edu/ts-rap-for-home',
          key: 'rap_for_home'
        }
      ]
    }
  },
  watch: {
    '$store.getters.patch_request_actions_count': function (newVal, oldVal) {
      this.options[0].badge_content = newVal // TODO: Find by key
      // This is ugly and disgusting, but it works
      // badge_content really didn't want to update otherwise
    }
  },
  computed: {
    visible_options() {
      return this.options.filter(option => {
        console.log(option.disabled_text)
        if (option.visible) {
          return option.visible() || (option.disabled_text && option.disabled_text())
        } else {
          return true
        }
      })
    }
  }
}
</script>

<style scoped>
.cursor-disabled {
  cursor: not-allowed;
}
.no-perm {
  color: var(--red);
}
</style>
